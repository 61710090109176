/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, Image, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O  J A N Ě "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(128, 128, 128, 1)"}} name={"dvstyzmxxr"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Text className="text-box text-box--justify fs--14" style={{"maxWidth":1000}} content={"<span style=\"color: var(--white);\">JMENUJI SE JANA A VYSTUDOVALA JSEM OBOR ARCHITEKTURA A STAVITELSTVÍ NA ČVUT V PRAZE, STAVEBNÍ FAKULTĚ. PRO OBOR JSEM SE NADCHLA S TUŽKOU A FIXEM V RUCE A PŘI STUDIU ZÍSKALA LÁSKU PRO JAK ARCHITEKTONICKÉ, TAK URBANISTICKÉ NÁVRHY A TECHNICKÉ ŘEŠENÍ NAVRHOVANÝCH OBJEKTŮ. NYNÍ ŽIJI SVŮJ SEN, KDY MOHU SKLOUBIT TO, CO MILUJI. OD NÁVRHU TUŽKOU HMOTOVÉHO ŘEŠENÍ, ARCHITEKTONICKÝM ZPRACOVÁNÍM OBJEKTŮ A DESIGNU INTERIÉRŮ, PO TECHNICKÉ ZPRACOVÁNÍ S VYTVOŘENÍM STAVEBNÍHO POVOLENÍ ČI PROVÁDĚCÍ DOKUMENTACE PRO VÁŠ PROJEKT. BYDLENÍ VYTVÁŘÍM S MYŠLENKOU PRO ABSOLUTNÍ FUNKČNOST A PRAKTIČNOST UŽ JEN PRO TO, ŽE JE INVESTICÍ NA CELÝ ŽIVOT...</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":595}} src={"https://cdn.swbpg.com/t/13459/038500f3dfed4f0fbf7b01d86c38f1c5_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13459/038500f3dfed4f0fbf7b01d86c38f1c5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/038500f3dfed4f0fbf7b01d86c38f1c5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/038500f3dfed4f0fbf7b01d86c38f1c5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13459/038500f3dfed4f0fbf7b01d86c38f1c5_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box fs--13" content={"I N G . &nbsp;A R C H . &nbsp;J A N A &nbsp;L O R E N C O V Á"}>
              </Title>

              <Text className="text-box fs--12" content={"Hlaváčkova 3205, 440 01 Louny\n<br>+420 725 649 523<br>&nbsp;lorencovajana@gmail.com<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}